
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useToast } from "./ui/use-toast";
import { z } from "zod";

const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  mobile: z.string().regex(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
  pincode: z.string().regex(/^[0-9]{6}$/, "Pin code must be 6 digits"),
});

interface LeadFormProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
}

const LeadForm = ({ open, onOpenChange, title }: LeadFormProps) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    pincode: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { toast } = useToast();

  const openWhatsApp = (name: string, mobile: string, pincode: string) => {
    const phoneNumber = "916350386061"; // Added country code (91) for India
    const message = encodeURIComponent(`Namaskar, I am ${name} (${mobile}) from pincode ${pincode}. I'm interested in Shiv Manglam Financial Services, please connect with me.`);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      formSchema.parse(formData);
      
      const form = e.target as HTMLFormElement;
      const formData2 = new FormData(form);
      
      try {
        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData2 as any).toString(),
        });
        
        if (response.ok) {
          toast({
            title: "Success!",
            description: title === "Become a Member" 
              ? "Thank you for your interest in becoming a member. We will contact you shortly."
              : "Thank you for your interest. Our team will connect with you shortly.",
          });
          
          // Open WhatsApp with user details
          openWhatsApp(formData.name, formData.mobile, formData.pincode);
          
          setFormData({ name: "", mobile: "", pincode: "" });
          onOpenChange(false);
        }
      } catch (error) {
        console.error("Form submission error:", error);
      }
      
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          if (err.path[0]) {
            newErrors[err.path[0].toString()] = err.message;
          }
        });
        setErrors(newErrors);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const formName = title === "Become a Member" ? "member-form" : "brochure-form";

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <form 
          name={formName}
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit} 
          className="space-y-4"
        >
          <input type="hidden" name="form-name" value={formName} />
          <div hidden>
            <input name="bot-field" />
          </div>
          <div>
            <Input
              placeholder="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <p className="text-sm text-destructive mt-1">{errors.name}</p>
            )}
          </div>
          <div>
            <Input
              placeholder="Mobile Number"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              maxLength={10}
            />
            {errors.mobile && (
              <p className="text-sm text-destructive mt-1">{errors.mobile}</p>
            )}
          </div>
          <div>
            <Input
              placeholder="Pin Code"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              maxLength={6}
            />
            {errors.pincode && (
              <p className="text-sm text-destructive mt-1">{errors.pincode}</p>
            )}
          </div>
          <Button type="submit" className="w-full">
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LeadForm;
