import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useState } from "react";

interface ServiceCardProps {
  title: string;
  description: string;
  expandedContent?: string;
  icon: React.ReactNode;
}

const ServiceCard = ({ title, description, expandedContent, icon }: ServiceCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card 
      className={`transition-all duration-300 ease-in-out ${
        isHovered ? 'scale-105 h-[400px]' : 'h-[180px]'
      } hover:shadow-lg relative overflow-hidden`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardHeader>
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
            <div className="text-primary">{icon}</div>
          </div>
          <CardTitle className="text-xl">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent>
        <div className={`transition-opacity duration-300 ${isHovered ? 'opacity-0' : 'opacity-100'}`}>
          <CardDescription className="text-base">{description}</CardDescription>
        </div>
        {expandedContent && (
          <div 
            className={`absolute left-0 right-0 px-6 transition-all duration-300 ${
              isHovered ? 'opacity-100 top-32' : 'opacity-0 top-full'
            }`}
          >
            <p className="text-base leading-relaxed text-gray-600">{expandedContent}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ServiceCard;