
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Coins, PiggyBank, CreditCard, Repeat } from "lucide-react";

const SpecialSchemes = () => {
  const schemes = [
    {
      title: "Attractive Daily Deposit Scheme",
      subtitle: "For Small & Medium Businesses",
      description: "Our daily deposit scheme helps businesses manage their daily cash flow efficiently while earning attractive returns. Perfect for shops, restaurants, and small enterprises.",
      icon: <Coins className="w-6 h-6 text-primary" />
    },
    {
      title: "Gullak Scheme",
      subtitle: "For Children",
      description: "Start building your child's future with our special savings scheme. Inculcate the habit of saving from an early age with attractive interest rates and special benefits.",
      icon: <PiggyBank className="w-6 h-6 text-primary" />
    },
    {
      title: "Two & Four Wheeler Loans",
      subtitle: "with Daily EMI Option",
      description: "Make your dream of owning a vehicle come true with our flexible loan options. Our daily EMI facility makes repayment easy and manageable for everyone.",
      icon: <CreditCard className="w-6 h-6 text-primary" />
    },
    {
      title: "Recurring Deposits",
      subtitle: "Flexible Saving Options",
      description: "Choose from our range of daily or monthly recurring deposit plans with flexible time frames. For your convenience, our society representative will visit you to collect the amount and provide an instant PoS receipt.",
      icon: <Repeat className="w-6 h-6 text-primary" />
    }
  ];

  return (
    <div className="py-20 bg-accent/20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Special Schemes</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Explore our specially designed schemes tailored for specific needs and aspirations
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {schemes.map((scheme, index) => (
            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
              <CardHeader>
                <div className="flex items-center gap-4 mb-2">
                  <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                    {scheme.icon}
                  </div>
                </div>
                <CardTitle className="text-xl">{scheme.title}</CardTitle>
                <CardDescription className="text-sm text-primary font-medium">
                  {scheme.subtitle}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 text-justify">{scheme.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecialSchemes;
