
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import InvestmentCalculator from "./calculators/InvestmentCalculator";
import LoanCalculator from "./calculators/LoanCalculator";
import { Calculator as CalcIcon } from "lucide-react";

const Calculator = () => {
  return (
    <div className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center gap-3 mb-4">
            <CalcIcon className="h-8 w-8 text-primary" />
            <h2 className="text-3xl font-bold">Financial Calculators</h2>
          </div>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Plan your financial future with our easy-to-use calculators. Estimate your investment returns or calculate loan EMIs.
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <Tabs defaultValue="investment" className="w-full">
            <TabsList className="grid w-full grid-cols-2 mb-8 p-1 h-auto bg-muted">
              <TabsTrigger 
                value="investment" 
                className="text-lg py-3 px-6 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm transition-all"
              >
                Investment Calculator
              </TabsTrigger>
              <TabsTrigger 
                value="loan" 
                className="text-lg py-3 px-6 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm transition-all"
              >
                Loan Calculator
              </TabsTrigger>
            </TabsList>
            <TabsContent value="investment" className="mt-6">
              <InvestmentCalculator />
            </TabsContent>
            <TabsContent value="loan" className="mt-6">
              <LoanCalculator />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
