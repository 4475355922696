import Navbar from "@/components/Navbar";

const Blog = () => {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-6">Our Blog</h2>
        <div className="grid gap-6">
          <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Coming Soon</h3>
            <p className="text-gray-600">Blog posts will be available shortly.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;