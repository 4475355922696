
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hero from "@/components/Hero";
import Services from "@/components/Services";
import About from "@/components/About";
import Contact from "@/components/Contact";
import Header from "@/components/Header";
import Calculator from "@/components/Calculator";
import SpecialSchemes from "@/components/SpecialSchemes";
import { Button } from "@/components/ui/button";
import { RefreshCw, MessageCircle } from "lucide-react";

interface LocationState {
  scrollTo?: string;
}

const Index = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  useEffect(() => {
    if (state?.scrollTo) {
      const element = document.getElementById(state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      // Clear the state after scrolling
      window.history.replaceState({}, document.title);
    }
  }, [state?.scrollTo]);

  const handleReload = () => {
    window.location.reload();
  };

  const openWhatsApp = () => {
    const phoneNumber = "916350386061"; // Added country code (91) for India
    const message = encodeURIComponent("Namaskar, I wanted to talk about Shiv Manglam Financial Services, Please connect back");
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="min-h-screen relative">
      <Button
        variant="ghost"
        size="icon"
        className="fixed top-4 right-4 z-50"
        onClick={handleReload}
        aria-label="Reload page"
      >
        <RefreshCw className="h-5 w-5" />
      </Button>
      <Header />
      <div className="pt-20">
        <Hero />
        <div id="services">
          <Services />
        </div>
        <SpecialSchemes />
        <div id="why-choose-us">
          <About />
        </div>
        <div id="calculators">
          <Calculator />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </div>
      
      {/* WhatsApp Button - Made larger and more noticeable */}
      <Button
        onClick={openWhatsApp}
        className="fixed bottom-6 left-6 z-50 rounded-full bg-[#25D366] hover:bg-[#128C7E] shadow-lg p-5 w-16 h-16 flex items-center justify-center animate-pulse hover:animate-none"
        aria-label="Chat on WhatsApp"
      >
        <MessageCircle className="h-8 w-8 text-white" />
      </Button>
    </div>
  );
};

export default Index;
