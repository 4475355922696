
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import { Button } from "./ui/button";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (id: string) => {
    if (location.pathname !== "/") {
      // If not on home page, first navigate to home then scroll
      navigate("/", { state: { scrollTo: id } });
    } else {
      // If already on home page, just scroll
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          <Link to="/">
            <img
              src="/lovable-uploads/cb54c118-92bb-45ff-b2bd-1a8d6e732c42.png"
              alt="Shiv Mangalam Credit Co Operative Society Logo"
              className="h-16 cursor-pointer"
            />
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center gap-6">
            <button
              onClick={() => scrollToSection("services")}
              className="text-gray-700 hover:text-primary transition-colors font-bold"
            >
              Our Services
            </button>
            <button
              onClick={() => scrollToSection("calculators")}
              className="text-gray-700 hover:text-primary transition-colors font-bold"
            >
              Calculator
            </button>
            <button
              onClick={() => scrollToSection("contact")}
              className="text-gray-700 hover:text-primary transition-colors font-bold"
            >
              Contact Us
            </button>
            <Link
              to="/about"
              className="text-gray-700 hover:text-primary transition-colors font-bold"
            >
              About Us
            </Link>
          </nav>

          {/* Mobile Menu Button */}
          <Button
            variant="ghost"
            size="icon"
            className="md:hidden"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </Button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <nav className="md:hidden py-4 space-y-4 border-t">
            <div className="flex flex-col items-center gap-4">
              <button
                onClick={() => {
                  scrollToSection("services");
                  setIsMenuOpen(false);
                }}
                className="text-gray-700 hover:text-primary transition-colors w-full py-2 font-bold"
              >
                Our Services
              </button>
              <button
                onClick={() => {
                  scrollToSection("calculators");
                  setIsMenuOpen(false);
                }}
                className="text-gray-700 hover:text-primary transition-colors w-full py-2 font-bold"
              >
                Calculator
              </button>
              <button
                onClick={() => {
                  scrollToSection("contact");
                  setIsMenuOpen(false);
                }}
                className="text-gray-700 hover:text-primary transition-colors w-full py-2 font-bold"
              >
                Contact Us
              </button>
              <Link
                to="/about"
                className="text-gray-700 hover:text-primary transition-colors w-full py-2 text-center font-bold"
                onClick={() => setIsMenuOpen(false)}
              >
                About Us
              </Link>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
