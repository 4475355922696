
import { Shield, Users, TrendingUp } from "lucide-react";

const About = () => {
  return (
    <div className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Why Choose Us</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            With over 15 years of trust and excellence in cooperative society services, we continue to serve our members with dedication and integrity.
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              src="https://images.unsplash.com/photo-1519389950473-47ba0277781c"
              alt="Team Meeting"
              className="rounded-lg shadow-xl"
            />
          </div>
          
          <div className="space-y-8">
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                  <Shield className="w-6 h-6 text-primary" />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Trust & Security</h3>
                <p className="text-gray-600">
                  Licensed and regulated cooperative society with a strong focus on member security and trust.
                </p>
              </div>
            </div>
            
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                  <Users className="w-6 h-6 text-primary" />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Member-Centric</h3>
                <p className="text-gray-600">
                  Dedicated to serving our members with personalized financial solutions and support.
                </p>
              </div>
            </div>
            
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                  <TrendingUp className="w-6 h-6 text-primary" />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Growth & Innovation</h3>
                <p className="text-gray-600">
                  Continuous focus on technological advancement and innovative financial products.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
          <div className="text-center p-6 bg-primary/5 rounded-lg">
            <h3 className="text-4xl font-bold text-primary mb-2">15+</h3>
            <p className="text-gray-600">Years of Service</p>
          </div>
          <div className="text-center p-6 bg-primary/5 rounded-lg">
            <h3 className="text-4xl font-bold text-primary mb-2">2K+</h3>
            <p className="text-gray-600">Happy Members</p>
          </div>
          <div className="text-center p-6 bg-primary/5 rounded-lg">
            <h3 className="text-4xl font-bold text-primary mb-2">1000+</h3>
            <p className="text-gray-600">Loans Given</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
