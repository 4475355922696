
import Header from "@/components/Header";
import { useEffect } from "react";

const AboutUs = () => {
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const directors = [
    {
      id: 1,
      image: "/lovable-uploads/176d639b-6687-48ac-a71c-5ae7b72ff1bd.png",
      name: "Shri Satya Prakash Bohra",
      role: "Founder & President"
    },
    {
      id: 2,
      image: "/lovable-uploads/e0b47c65-95f4-47f8-9f26-aa3c752cab9f.png",
      name: "Shri Chetan Mahanandani",
      role: "Founder & Secretary"
    },
    {
      id: 7,
      image: "/lovable-uploads/edd66578-7f7e-4606-85fe-2ce1053f95a7.png",
      name: "Shri Naresh Bohra",
      role: "CEO"
    }
  ];

  return (
    <div className="min-h-screen">
      <Header />
      <div className="pt-20">
        <div className="w-full h-[40vh] relative">
          <div className="absolute inset-0 bg-primary flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white">Our Leadership</h1>
          </div>
        </div>
        
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {directors.map((director) => (
              <div key={director.id} className="flex flex-col">
                <div className="aspect-square overflow-hidden rounded-lg shadow-lg mb-4 flex items-center justify-center bg-white">
                  <img
                    src={director.image}
                    alt={director.name}
                    className={`w-full h-full object-contain object-center ${
                      director.id === 2 ? 'p-2' : ''
                    }`}
                  />
                </div>
                <div className="text-center">
                  <h3 className="font-semibold text-lg text-gray-900">
                    {director.name}
                  </h3>
                  <p className="text-gray-600 mt-1">
                    {director.role}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="max-w-3xl mx-auto mt-16">
            <h2 className="text-3xl font-bold mb-8">Our Journey</h2>
            <div className="prose prose-lg text-justify">
              <p>
                Established in Nov 2009, Shiv Mangalam Credit Co-operative Society Ltd. began its journey with a vision to empower the local community through financial inclusion and cooperative banking services. Founded by a group of dedicated individuals who believed in the power of collective growth, our society has since grown to become a trusted name in cooperative banking.
              </p>
              <p>
                Under the leadership of our founding members and with the support of our growing member base, we have consistently worked towards providing accessible financial services to our community. Our board of directors, comprising experienced professionals from various fields, has been instrumental in guiding the society through its growth journey.
              </p>
              <p>
                Today, we serve thousands of members across the region, offering a wide range of financial services including savings accounts, fixed deposits, and various loan products. Our success is built on the foundation of trust, transparency, and commitment to our members' financial well-being.
              </p>
            </div>
          </div>
        </div>

        <footer className="bg-gray-100 py-12 mt-16">
          <div className="container mx-auto px-4">
            <div className="text-center">
              <h3 className="text-xl font-semibold mb-4">Connect With Us</h3>
              <p className="text-gray-600">
                Join us in our mission to create a financially empowered community.
              </p>
              <p className="text-gray-600 mt-4">
                © 2024 Shiv Mangalam Credit Co-operative Society Ltd. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AboutUs;
