
import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Calculator, Info } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent } from "@/components/ui/card";

interface ServiceRate {
  name: string;
  rate: number;
  description: string;
  type: "one-time" | "monthly" | "daily";
}

const services: ServiceRate[] = [
  { 
    name: "Savings", 
    rate: 3, 
    description: "3% per annum",
    type: "one-time"
  },
  { 
    name: "Monthly Recurring Deposit", 
    rate: 10, 
    description: "10% per annum",
    type: "monthly"
  },
  { 
    name: "Daily Deposit", 
    rate: 6, 
    description: "6% per annum",
    type: "daily"
  },
  { 
    name: "Fixed Deposit", 
    rate: 10, 
    description: "10% per annum (12 months) + 1% extra for senior citizens and women",
    type: "one-time"
  },
];

const InvestmentCalculator = () => {
  const [amount, setAmount] = useState<string>("");
  const [service, setService] = useState<string>("");
  const [years, setYears] = useState<string>("1");
  const [months, setMonths] = useState<string>("0");
  const [returns, setReturns] = useState<number>(0);
  const [totalInvestment, setTotalInvestment] = useState<number>(0);
  const [selectedRate, setSelectedRate] = useState<number>(0);

  useEffect(() => {
    const selectedService = services.find(s => s.name === service);
    if (selectedService) {
      setSelectedRate(selectedService.rate);
    }
  }, [service]);

  useEffect(() => {
    if (amount && service && (years || months)) {
      const principal = parseFloat(amount);
      const totalYears = parseInt(years) + parseInt(months) / 12;
      const rate = selectedRate / 100;
      
      const selectedService = services.find(s => s.name === service);
      
      if (!selectedService) return;

      let totalAmount = 0;
      let totalInvested = 0;

      switch (selectedService.type) {
        case "monthly":
          const totalMonths = (parseInt(years) * 12) + parseInt(months);
          totalInvested = principal * totalMonths;
          totalAmount = principal * ((Math.pow(1 + (rate/12), totalMonths) - 1) / (rate/12));
          break;

        case "daily":
          const totalDays = (parseInt(years) * 365) + (parseInt(months) * 30);
          totalInvested = principal * totalDays;
          totalAmount = principal * ((Math.pow(1 + (rate/365), totalDays) - 1) / (rate/365));
          break;

        case "one-time":
          totalInvested = principal;
          totalAmount = principal * Math.pow(1 + rate, totalYears);
          break;
      }

      setTotalInvestment(totalInvested);
      setReturns(totalAmount);
    }
  }, [amount, service, years, months, selectedRate]);

  return (
    <Card className="p-6 shadow-lg border-2 border-gray-100">
      <CardContent className="space-y-6 pt-4">
        <div className="flex items-center gap-3 text-xl font-semibold text-primary">
          <Calculator className="h-6 w-6" />
          <h3>Investment Return Calculator</h3>
        </div>

        <Alert variant="default" className="bg-orange-50 border-orange-200">
          <Info className="h-4 w-4 text-orange-500" />
          <AlertDescription className="text-orange-800">
            The calculations shown are for representation purposes only. For accurate and final numbers, 
            please contact the Shiv Mangalam team at <span className="font-semibold">6350386061</span>.
          </AlertDescription>
        </Alert>
        
        <div className="grid gap-6">
          <div className="space-y-2">
            <Label className="text-base">Select Investment Type</Label>
            <Select value={service} onValueChange={setService}>
              <SelectTrigger className="text-lg">
                <SelectValue placeholder="Select service" />
              </SelectTrigger>
              <SelectContent>
                {services.map((s) => (
                  <SelectItem key={s.name} value={s.name}>
                    {s.name} - {s.description}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label htmlFor="amount" className="text-base">
              {service.includes("Monthly") ? "Monthly Investment Amount (₹)" :
               service.includes("Daily") ? "Daily Investment Amount (₹)" :
               "Investment Amount (₹)"}
            </Label>
            <Input
              id="amount"
              type="number"
              min="100"
              max="10000000"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount (Min: ₹100, Max: ₹1,00,00,000)"
              className="text-lg"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="years" className="text-base">Years</Label>
              <Select value={years} onValueChange={setYears}>
                <SelectTrigger className="text-lg">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {Array.from({ length: 11 }, (_, i) => (
                    <SelectItem key={i} value={i.toString()}>
                      {i} {i === 1 ? "Year" : "Years"}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="months" className="text-base">Months</Label>
              <Select value={months} onValueChange={setMonths}>
                <SelectTrigger className="text-lg">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {Array.from({ length: 12 }, (_, i) => (
                    <SelectItem key={i} value={i.toString()}>
                      {i} {i === 1 ? "Month" : "Months"}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          {returns > 0 && (
            <Card className="mt-6 bg-gradient-to-br from-green-50 to-green-100/50 border-none">
              <CardContent className="pt-6">
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Returns:</span>
                    <span className="text-2xl font-bold text-green-600">₹{returns.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Investment:</span>
                    <span className="text-lg font-semibold text-gray-900">₹{totalInvestment.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Interest Earned:</span>
                    <span className="text-lg font-semibold text-gray-900">₹{(returns - totalInvestment).toFixed(2)}</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default InvestmentCalculator;
