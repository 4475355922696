import { useState } from "react";
import { Download } from "lucide-react";
import { Button } from "./ui/button";
import DownloadBrochure from "./DownloadBrochure";

const Navbar = () => {
  const [showBrochure, setShowBrochure] = useState(false);

  return (
    <nav className="bg-primary text-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-between space-y-2 md:flex-row md:space-y-0">
          <div className="text-center md:text-left w-full md:w-auto">
            <h1 className="text-xl md:text-2xl font-bold">
              Shiv Mangalam Credit Co Operative Society
            </h1>
            <p className="text-sm font-medium text-primary-foreground/80">
              बिना सहकार नहीं संस्कार, बिना संस्कार नहीं उद्धार
            </p>
          </div>
          <div className="flex items-center gap-4 w-full md:w-auto justify-center md:justify-end">
            <Button
              variant="outline"
              size="sm"
              className="border-white text-white hover:bg-white/20"
              onClick={() => setShowBrochure(true)}
            >
              <Download className="mr-2 h-4 w-4" />
              Download Brochure
            </Button>
          </div>
        </div>
      </div>
      <DownloadBrochure open={showBrochure} onOpenChange={setShowBrochure} />
    </nav>
  );
};

export default Navbar;