
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import LeadForm from "./LeadForm";

const Hero = () => {
  const [showMemberForm, setShowMemberForm] = useState(false);

  return (
    <div className="relative bg-gradient-to-r from-primary to-primary/80 py-24 text-white">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <div className="space-y-2">
              <h1 className="text-2xl md:text-3xl font-bold leading-tight">
                Shiv Mangalam Credit Co Operative Society
              </h1>
              <p className="text-base opacity-90 font-medium">
                (Inspired by Sahakar Bharti)
              </p>
            </div>
            <p className="text-xl opacity-90 font-medium">
              बिना संस्कार नहीं सहकार, बिना सहकार नहीं उद्धार
            </p>
            <div className="flex gap-4">
              <Button 
                className="bg-secondary hover:bg-secondary/90 text-white"
                onClick={() => setShowMemberForm(true)}
              >
                Connect with Our Experts
                <ChevronRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
          <div className="hidden md:block">
            <img
              src="https://images.unsplash.com/photo-1460925895917-afdab827c52f"
              alt="Banking Services"
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-background to-transparent" />
      <LeadForm 
        open={showMemberForm} 
        onOpenChange={setShowMemberForm}
        title="Connect with Our Experts"
      />
    </div>
  );
};

export default Hero;
