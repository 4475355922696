
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calculator, Info } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent } from "@/components/ui/card";

const LOAN_INTEREST_RATE = 18; // 18% per annum

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState<string>("");
  const [tenure, setTenure] = useState<string>("12"); // in months
  const [emi, setEmi] = useState<number>(0);

  useEffect(() => {
    if (loanAmount && tenure) {
      const principal = parseFloat(loanAmount);
      const numberOfMonths = parseInt(tenure);
      const ratePerMonth = LOAN_INTEREST_RATE / (12 * 100); // monthly interest rate
      
      // EMI calculation formula: P * r * (1 + r)^n / ((1 + r)^n - 1)
      const emiAmount = 
        (principal * ratePerMonth * Math.pow(1 + ratePerMonth, numberOfMonths)) /
        (Math.pow(1 + ratePerMonth, numberOfMonths) - 1);
      
      setEmi(emiAmount);
    }
  }, [loanAmount, tenure]);

  return (
    <Card className="p-6 shadow-lg border-2 border-gray-100">
      <CardContent className="space-y-6 pt-4">
        <div className="flex items-center gap-3 text-xl font-semibold text-primary">
          <Calculator className="h-6 w-6" />
          <h3>Loan EMI Calculator</h3>
        </div>

        <Alert variant="default" className="bg-blue-50 border-blue-200">
          <Info className="h-4 w-4 text-blue-500" />
          <AlertDescription className="text-blue-800">
            Interest Rate: {LOAN_INTEREST_RATE}% per annum
          </AlertDescription>
        </Alert>
        
        <div className="grid gap-6">
          <div className="space-y-2">
            <Label htmlFor="loanAmount" className="text-base">Loan Amount (₹)</Label>
            <Input
              id="loanAmount"
              type="number"
              min="0"
              value={loanAmount}
              onChange={(e) => setLoanAmount(e.target.value)}
              placeholder="Enter loan amount"
              className="text-lg"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="tenure" className="text-base">Loan Tenure</Label>
            <Select value={tenure} onValueChange={setTenure}>
              <SelectTrigger className="text-lg">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {Array.from({ length: 60 }, (_, i) => i + 1).map((months) => (
                  <SelectItem key={months} value={months.toString()}>
                    {months} {months === 1 ? "Month" : "Months"}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {emi > 0 && (
            <Card className="mt-6 bg-gradient-to-br from-primary/5 to-primary/10 border-none">
              <CardContent className="pt-6">
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Monthly EMI:</span>
                    <span className="text-2xl font-bold text-primary">₹{emi.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Amount Payable:</span>
                    <span className="text-lg font-semibold text-gray-900">₹{(emi * parseInt(tenure)).toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Interest:</span>
                    <span className="text-lg font-semibold text-gray-900">₹{(emi * parseInt(tenure) - parseFloat(loanAmount)).toFixed(2)}</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}

          <div className="p-4 bg-gray-50 rounded-lg text-sm text-gray-600">
            <p>Disclaimer: The actual loan interest rate may vary based on individual financial history and creditworthiness. Shiv Mangalam Organisation reserves the right to modify interest rates for loans and investments. All decisions regarding interest rates are final and binding.</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default LoanCalculator;
