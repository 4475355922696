
import { Building2, PiggyBank, Landmark, Users, CreditCard, ChartBar } from "lucide-react";
import ServiceCard from "./ServiceCard";

const Services = () => {
  const services = [
    {
      title: "Savings Accounts",
      description: "Secure savings accounts with competitive interest rates and flexible withdrawal options.",
      expandedContent: "Secure your future with Shiv Mangalam Credit Cooperative Society's Savings Account, offering a competitive 3% interest rate. Enjoy safe, hassle-free banking, easy deposits & withdrawals, zero hidden charges, and personalized service. Grow your savings while ensuring liquidity and financial security. Join us today for better returns and trusted financial solutions!",
      icon: <PiggyBank className="w-6 h-6" />,
    },
    {
      title: "Fixed Deposits",
      description: "Long-term investment options with guaranteed returns and tax benefits.",
      expandedContent: "Maximize your wealth with our Fixed Deposit schemes offering competitive interest rates up to 10% annually for men under 60 years, and special rates of 11% for senior citizens and women. Choose from flexible tenure options ranging from 6 months to 5 years. Enjoy guaranteed returns, quarterly interest payouts, and the option to auto-renew your deposits.",
      icon: <Building2 className="w-6 h-6" />,
    },
    {
      title: "Personal Loans",
      description: "Quick personal loans with minimal documentation and competitive interest rates.",
      expandedContent: "Get instant personal loans up to ₹5 lakhs with minimal documentation and quick approval process. Enjoy attractive rate of interest, flexible repayment terms from 12 to 60 months, and no prepayment charges. Perfect for emergency needs, education, wedding, or travel!",
      icon: <CreditCard className="w-6 h-6" />,
    },
    {
      title: "Business Loans",
      description: "Support for small businesses and entrepreneurs with flexible repayment options.",
      expandedContent: "Fuel your business growth with our tailored business loan solutions. Access funds up to ₹25 lakhs with minimal collateral requirements. Benefit from attractive rate of interest, flexible repayment schedules, and dedicated relationship manager support. Ideal for expansion, working capital, or equipment purchase!",
      icon: <Landmark className="w-6 h-6" />,
    },
    {
      title: "Group Loans",
      description: "Special loan schemes for self-help groups and cooperative societies.",
      expandedContent: "Empower your community with our group loan schemes designed specifically for self-help groups and cooperative societies. Get loans up to ₹10 lakhs with group guarantee, enjoy attractive rate of interest, and flexible repayment options. Perfect for small businesses, agricultural activities, and community development projects!",
      icon: <Users className="w-6 h-6" />,
    },
    {
      title: "Investment Plans",
      description: "Customized investment solutions for long-term wealth creation.",
      expandedContent: "Create lasting wealth with our diverse investment plans tailored to your financial goals. Choose from multiple investment options designed to help you achieve your dreams. Benefit from professional portfolio management, regular performance reviews, and tax-efficient investment strategies. Start your wealth creation journey today!",
      icon: <ChartBar className="w-6 h-6" />,
    },
  ];

  return (
    <div className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Choose from our comprehensive range of financial services designed to meet your personal and business needs.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service) => (
            <ServiceCard key={service.title} {...service} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
